import { Form, Formik } from 'formik';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { number, object } from 'yup';

import { RightArrowIcon } from '@/components/Icon/ArrowIconUiKit';
import { Input } from '@/components/Inputs';
import FormButton from '@/components/Inputs/FormButton';
import Text from '@/components/Text';

const MyFlex = styled(Flex)`
  @media (max-width: 820px) {
    flex-direction: column;
    height: 600px;
  }
`;
const MyInnerFlexLeft = styled(Flex)`
  min-width: 568px;
  @media (max-width: 820px) {
    height: 300px;
    width: 100%;
    min-width: 100%;
  }
`;
const MyInnerFlexRight = styled(Flex)`
  @media (max-width: 820px) {
    height: 400px;
    width: 100%;
  }
`;
const Rechner = ({ widgetContent }) => {
  const router = useRouter();
  const validationSchema = () => {
    const fields = {
      einkommen: number()
        .required('Diese Feld ist erforderlich')
        .integer('Zahl bitte')
        .typeError('Diese Feld ist erforderlich')
        .default('0'),
    };
    return object().shape(fields);
  };

  const [getInitialValues] = useState(() => {
    return {
      einkommen: widgetContent?.defaultValueEinkommen,
    };
  });

  const formikConfig = {
    initialValues: getInitialValues ?? validationSchema.cast(),
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      router.push({
        pathname: '/finanzierung/haushaltsrechner',
        query: {
          abschnitt: 'formular',
          netIncome: values.einkommen,
        },
      });
    },
  };

  return (
    <MyFlex flexDirection="row" height="370px" sx={{ borderRadius: '10px', overflow: 'hidden' }}>
      <MyInnerFlexLeft width="50%" bg="#3B4C5C" alignItems="center">
        <Box mx={['2.688rem', '2.688rem', '2.688rem']}>
          <Text color="#FFF" mb="1.875rem" variant="headline" mt={['0', '0', '0']}>
            {widgetContent.title}
          </Text>
          <Text color="#FFF" lineHeight="1.763rem" mb="1.875rem">
            {widgetContent.text}
          </Text>
          <Formik {...formikConfig}>
            <Form>
              <Flex alignItems="flex-end">
                <Box>
                  <Input
                    customFontSize="14px"
                    isInvert
                    name="einkommen"
                    placeholder=""
                    type="currency"
                    title={widgetContent.labelEinkommen}
                    my={['0', '0']}
                  />
                </Box>
                <FormButton
                  submit
                  isInvert
                  Icon={RightArrowIcon}
                  wrapperProps={{ marginLeft: '1rem' }}
                  text={widgetContent.buttonText}
                  variant="secondary"
                  notFullWidth
                  width={['100%', 'auto']}
                />
              </Flex>
            </Form>
          </Formik>
        </Box>
      </MyInnerFlexLeft>
      <MyInnerFlexRight width="50%" sx={{ position: 'relative' }}>
        <Box bg="transparent" sx={{ position: 'absolute', height: '100%', width: '100%', zIndex: '10000' }} />
        <NextImage
          src={widgetContent.image.url}
          layout="fill"
          objectFit="cover"
          objectPosition="center center"
          priority
        />
      </MyInnerFlexRight>
    </MyFlex>
  );
};

Rechner.propTypes = {
  widgetContent: PropTypes.shape({
    buttonText: PropTypes.string.isRequired,
    defaultValueEinkommen: PropTypes.number.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    labelEinkommen: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default Rechner;
