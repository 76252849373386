import { Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import Card from '@/components/Card';
import Text from '@/components/Text';
import financingCalculatorStart from '@/validation/financingCalculatorStart';

import Button from '../Button/Button';
import { Input } from '../Inputs';
import FormButton from '../Inputs/FormButton';
import InterestCost from './InterestCosts';

const MyBox = styled(Box)`
  width: 100%;
  @media (min-width: 469px) {
    width: auto;
  }
`;
const MyFlex = styled(Flex)`
  flex-direction: column-reverse;
  @media (min-width: 469px) {
    flex-direction: row;
  }
`;
const StyledCard = styled(Card)`
  width: 100%;

  @media (min-width: 768px) {
    width: 90%;
  }
  @media (min-width: 821px) {
    width: 60%;
  }
`;

const FinancingCalculator = (index) => {
  const router = useRouter();
  const validationSchema = financingCalculatorStart();
  const [getInitialValues] = useState(() => {
    return { ...validationSchema.cast(), ...router.query };
  });

  const formikConfig = {
    initialValues: getInitialValues,
    validationSchema,
    onSubmit: async (values) => {
      await router.replace({ pathname: '', query: values }, undefined, {
        shallow: true,
      });
      await router.push({
        pathname: '/finanzierung/berater-kontaktieren',
        query: { ...values, planType: 'baufinanzierungStart' },
      });
      window.scrollTo(0, 0);
    },
  };
  const formRef = useRef();

  const pathForCalcButton = () => {
    router.push({
      pathname: `/finanzierung/baufinanzierung`,
      query: {
        buyingPrice: formRef?.current?.values.buyingPrice,
        equity: formRef?.current?.values?.calcResultStart?.equityCapital,
        repayment: formRef?.current?.values?.calcResultStart?.initialRepayment,
        nominalInterestRateFixationInYears: formRef?.current?.values?.calcResultStart?.fixedInterestRate,
        rechner: 'immobilien',
      },
    });
  };

  return (
    <StyledCard
      mb={['20px', '20px', '20px']}
      py={['15px', '15px', '30px']}
      px={['15px', '15px', '30px']}
      sx={{ position: 'relative', zIndex: '1' }}
    >
      {index ? null : (
        <Text color="darkGrey" variant="subhead" mb={['18px', '18px', '18px']}>
          Finanzierung berechnen
        </Text>
      )}

      <Formik {...formikConfig} innerRef={formRef}>
        <Form>
          <Flex flexDirection="row" flexWrap={['wrap', 'wrap', 'wrap']}>
            <Input
              width={['100%', '100%', '100%']}
              flexBasis={['100%', '100%', '50%']}
              name="buyingPrice"
              title="Kaufpreis*"
              placeholder=""
              type="currency"
            />
            <InterestCost errorMessage="Finanzierung nicht möglich" />
          </Flex>
          {index ? (
            <MyFlex
              alignItems="baseline"
              justifyContent="space-between"
              mt={index ? ['0', '0', '0'] : ['18px', '18px', '18px']}
            >
              <MyBox>
                <Button
                  data-testid="Individuell berechnen"
                  mb={[0, 0, 0]}
                  onClick={pathForCalcButton}
                  title="Individuell berechnen"
                  variant="secondary"
                  width={['100%', '100%', 'auto']}
                >
                  Individuell berechnen
                </Button>
              </MyBox>

              <MyBox mb={['0.875rem', '0.875rem', '0']} mt={['20px']}>
                <FormButton
                  data-testid="Beratung anfragen"
                  notFullWidth
                  Icon={false}
                  mb={[0, 0, 0]}
                  width={['100%', '100%', 'auto']}
                  text="Beratung anfragen"
                  title="Beratung anfragen"
                  variant="primary"
                />
              </MyBox>
            </MyFlex>
          ) : (
            <Flex
              alignItems="baseline"
              flexDirection={['column', 'column', 'row']}
              justifyContent="space-between"
              mt={['18px', '18px', '18px']}
            >
              <Button
                data-testid="Individuell berechnen"
                mb={[0, 0, 0]}
                onClick={pathForCalcButton}
                title="Individuell berechnen"
                variant="secondary"
                width={['100%', '100%', 'auto']}
              >
                Individuell berechnen
              </Button>
              <Box width={['100%', '100%', 'auto']} mb={['0.875rem', '0.875rem', '0']} mt={['20px']}>
                <FormButton
                  data-testid="Beratung anfragen"
                  notFullWidth
                  Icon={false}
                  mb={[0, 0, 0]}
                  text="Beratung anfragen"
                  title="Beratung anfragen"
                  width={['100%', '100%', 'auto']}
                  variant="primary"
                />
              </Box>
            </Flex>
          )}
        </Form>
      </Formik>
    </StyledCard>
  );
};

export default FinancingCalculator;
