import { Form, Formik } from 'formik';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import P from 'prop-types';
import * as React from 'react';
import { forwardRef } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { number, object, string } from 'yup';

import { Input } from '@/components/Inputs';
import { useBreakpoints, useEstateAutocomplete } from '@/hooks';
import toNumber from '@/utils/toNumber';
import SubmitButton from '@/widgets/RealEstateSearch/SubmitButton';

const MyBox = styled(Box)`
  display: block;
  @media (max-width: 820px) {
    display: none;
  }
`;

const FilterLight = forwardRef(({ onPaginationChange }, ref) => {
  const { t } = useTranslation('content-page-immobiliensuche');
  const content = t('filter', {}, { returnObjects: true });
  const breakpoint = useBreakpoints();
  const isMobile = ['small'].includes(breakpoint);

  const { query, push } = useRouter();
  const { data: options, handleInputChange, isFetching } = useEstateAutocomplete(content.labels);

  const validationSchema = object().shape({
    address: string()
      .trim()
      .default(query.address ?? ''),
    lat: number().default(toNumber(query?.lat) ?? undefined),
    lon: number().default(toNumber(query?.lon) ?? undefined),
    distance: number().default(toNumber(query?.distance) ?? 50),
    objectType: number().default(toNumber(query?.objectType) ?? -1),
    offerType: number().default(toNumber(query?.offerType) ?? 1),
    priceFrom: string().default(query?.priceFrom ?? undefined),
    priceTo: string().default(query?.priceTo ?? undefined),
    roomsFrom: number().default(toNumber(query?.roomsFrom) ?? -1),
    roomsTo: number().default(toNumber(query?.roomsTo) ?? -1),
    spaceFrom: string().default(query?.spaceFrom ?? undefined),
    spaceTo: string().default(query?.spaceTo ?? undefined),
    sorting: number().default(toNumber(query?.sorting) ?? 0),
  });

  const handleSubmit = async ({ address: addressValue, ...restValue }) => {
    onPaginationChange?.(1);

    const [address, lat, lon] = addressValue.split('#');

    await push(
      {
        pathname: '/immobilien/immobiliensuche/ergebnisse',
        query: { ...restValue, address, lat, lon },
      },
      undefined,
      {
        shallow: true,
        scroll: false,
      }
    );
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };

  const formikConfig = {
    initialValues: validationSchema.cast(),
    validationSchema,
  };

  return (
    <Formik {...formikConfig} onSubmit={handleSubmit}>
      {({ values }) => {
        return (
          <Form>
            <Flex flexWrap={['wrap']} alignItems="flex-end">
              <MyBox width={['100%', '100%', '45%']} mr={['0', '0', '1.25rem']} mb={['1.25rem', '0', '0']}>
                {isMobile ? null : (
                  <Input
                    group
                    type="select"
                    isInvert
                    title={content.labels.address.label}
                    name="address"
                    label={content.labels.address.label}
                    options={options}
                    isLoading={isFetching}
                    onInputChange={handleInputChange}
                    loadingMessage={() => `${content.loadingMessage}`}
                    customNoOptionText={content.labels.noOptions}
                    placeholder={content.labels.address.placeholder}
                    width="100%"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleSubmit(values);
                    }}
                  />
                )}
              </MyBox>
              <Box width={['100%', '100%', '45%']}>
                <SubmitButton wrapperProps={{ width: '100%' }} data={options} isInvert />
              </Box>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
});

FilterLight.propTypes = {
  isMobile: P.bool,
  isSorting: P.bool,
  onPaginationChange: P.func,
};

FilterLight.defaultProps = {
  isMobile: false,
  isSorting: false,
  onPaginationChange: undefined,
};

export default FilterLight;
