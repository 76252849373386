import NextImage from 'next/image';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Text from '@/components/Text';
import FilterLight from '@/widgets/RealEstateSearch/FilterIndex';

const MyFlex = styled(Flex)`
  @media (max-width: 820px) {
    flex-direction: column;
    height: 600px;
  }
`;
const MyInnerFlexLeft = styled(Flex)`
  min-width: 568px;
  @media (max-width: 820px) {
    height: 300px;
    width: 100%;
    min-width: 100%;
  }
`;
const MyInnerFlexRight = styled(Flex)`
  @media (max-width: 820px) {
    height: 400px;
    width: 100%;
  }
`;
const MyBox = styled(Box)`
  height: 80px;
  @media (max-width: 820px) {
    height: 40px;
  }
`;

const MyFilterFlex = styled(Flex)`
  position: absolute;
  bottom: 80px;
  @media (max-width: 820px) {
    top: 180px;
  }
`;
const Immobiliensuche = ({ widgetContent }) => {
  return (
    <MyFlex height="370px" sx={{ borderRadius: '10px', overflow: 'hidden' }}>
      <MyInnerFlexLeft width="50%" bg="#3B4C5C" alignItems="center">
        <Box mx={['2.688rem', '2.688rem', '2.688rem']}>
          <Text color="#FFF" mb="1.875rem" variant="headline" mt={['0', '0', '0']}>
            {widgetContent.title}
          </Text>
          <Text color="#FFF" lineHeight="1.763rem" mb="1.875rem">
            {widgetContent.text}
          </Text>
          <MyBox />
          <MyFilterFlex minWidth="500px" flexDirection="column">
            <FilterLight />
          </MyFilterFlex>
        </Box>
      </MyInnerFlexLeft>
      <MyInnerFlexRight width="50%" sx={{ position: 'relative' }}>
        <Box bg="transparent" sx={{ position: 'absolute', height: '100%', width: '100%', zIndex: '10000' }} />
        <NextImage
          src={widgetContent.image.url}
          layout="fill"
          objectFit="cover"
          objectPosition="center center"
          priority
        />
      </MyInnerFlexRight>
    </MyFlex>
  );
};

Immobiliensuche.propTypes = {
  widgetContent: PropTypes.shape({
    buttonText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default Immobiliensuche;
