import useTranslation from 'next-translate/useTranslation';
import NextImage from 'next/image';
import { Box, Flex } from 'rebass/styled-components';

import Section from '@/components/Section';
import Text from '@/components/Text';

const TrustBox = () => {
  const { t } = useTranslation('page-indice');
  const trustitem = t('trustitem', {}, { returnObjects: true });
  return (
    <Section
      bg="white"
      pb={['2rem', '6rem', '6rem']}
      pt={['3.75rem', '3.75rem', '3.75rem']}
      px={['1rem', '1rem', '1rem']}
    >
      <Flex
        alignItems="center"
        flexDirection={['column', 'row', 'row']}
        justifyContent="space-between"
        textAlign="center"
      >
        {trustitem.map?.((item) => {
          return (
            <Box
              mx={['1rem', '1rem', '0']}
              my={['2rem', '40px', '0.625rem']}
              px={['1rem', '1rem', '1rem']}
              width={['100%', '100%', '33%']}
              key={item.title}
            >
              <Box mb={['1.013rem', '1.013rem', '1.013rem']}>
                <NextImage
                  alt={item.image.alternativeText}
                  src={item.image.url}
                  width={item.image.width}
                  height={item.image.height}
                />
              </Box>
              <Text as="h5" mb={['0', '0', '0']} mx={['10%', '0', '0']} variant="subhead">
                {item.title}
              </Text>
              <Text as="p" mb={['0, 0, 0']} mx={['10%', '0', '0']} variant="checklist">
                {item.text}
              </Text>
            </Box>
          );
        })}
      </Flex>
    </Section>
  );
};

export default TrustBox;
