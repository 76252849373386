import useTranslation from 'next-translate/useTranslation';
import { Box, Flex } from 'rebass';

import Section from '@/components/Section';
import FinancingCalculator from '@/components/Startpage/FinancingCalculator';
import Text from '@/components/Text';

const Financing = () => {
  const { t } = useTranslation('page-indice');
  const financing = t('financing', {}, { returnObjects: true });
  return (
    <Section padded>
      <Flex justifyContent="center" mb={['3.75rem', '3.75rem', '3.75rem']}>
        <Box mt={['2rem', '2rem', '2rem']} textAlign="center" width={['100%', '100%', '80%']}>
          <Text as="h2" color="darkGrey" fontWeight={['700', '700', '700']} textAlign="center" variant="title">
            {financing.title}
          </Text>
          <Text textAlign="center" variant="body">
            {financing.text}
          </Text>
        </Box>
      </Flex>
      <Flex justifyContent="center" mb={['2rem', '2rem', '4rem']} width={['100%', '100%', '100%']}>
        <FinancingCalculator index />
      </Flex>
    </Section>
  );
};

export default Financing;
