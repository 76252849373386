import { useFormikContext } from 'formik';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { color, flexbox, position, size, space } from 'styled-system';

import { formatEuro, formatPercent } from '@/utils';

import { useValidDependingFields } from '../../hooks';
import { financingApi } from '../../services';
import ExtendedCard from '../ExtendedCard';
import LoadingOverlay from '../LoadingOverlay';
import Text from '../Text';

const dependingFields = ['buyingPrice'];

const LoaderWrapper = styled(Flex)`
  ${flexbox}
  ${space}
	${size}
	${position}
	${color}
	top: 50%;
  transform: translate(0, -50%);
  flex-direction: column;
  justify-content: center;
`;

LoaderWrapper.defaultProps = {
  position: 'absolute',
  color: 'primary',
};
const Amount = ({ isFetching, enabled, error, text, amount, ...rest }) => {
  return (
    <Flex
      opacity={isFetching ? 0 : 1}
      flexDirection="column"
      alignItems="flex-start"
      m="6"
      ml={['0', '0', '6']}
      {...rest}
    >
      <Text variant="small" textAlign="left">
        {text}
      </Text>
      {error ? (
        <Text variant="smallPrimary" textAlign="left" {...rest}>
          {error}
        </Text>
      ) : (
        <Text variant="headline" color={enabled ? 'darkGrey' : 'disabled'} my={[0, 0, 0]} textAlign="left">
          {enabled ? formatEuro(amount) : '00,00 €'}
        </Text>
      )}
    </Flex>
  );
};

Amount.propTypes = {
  amount: PropTypes.number.isRequired,
  enabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

Amount.defaultProps = {
  error: undefined,
};

const InterestCosts = ({ errorMessage }) => {
  const { t } = useTranslation('page-baufinanzierung');
  const { values, setFieldValue } = useFormikContext();
  const [formik, setForm] = useState('');
  const validForm = useValidDependingFields(dependingFields, false, true);
  const [statusOpen, setStatus] = useState(true);

  const { data, isSuccess, isFetching, status, isError } = useQuery(
    ['interestCostsStart', values],
    async () => {
      const formattedData = {
        purchasePrice: values.buyingPrice,
      };
      const req = await financingApi({
        url: '/calcBaufiCheckerRate',
        params: {
          objectPrice: formattedData.purchasePrice,
        },
      });

      return req.data;
    },
    {
      enabled: validForm,
      retry: 0,
      onSuccess: (value) => {
        setFieldValue('calcResultStart', value);
      },
      onError: () => setFieldValue('calcResultStart', null),
    }
  );
  useEffect(() => {
    setStatus(true);
    localStorage.removeItem('values');
  }, [data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const form = JSON.parse(localStorage.getItem('values')) || null;
    if (form !== null) {
      setStatus(false);
      setForm(form?.values);
    } else if (statusOpen) {
      setForm('');
    }
  });

  const enabled = validForm && isSuccess;

  const fixedInterestRate = data?.fixedInterestRate ?? 0;
  const incidentalPurchaseCostsRatio = data?.incidentalPurchaseCostsRatio ?? 0;
  const initialRepayment = data?.initialRepayment ?? 0;

  const boundInterestRate = data?.boundInterestRate ?? 0;
  const effectiveInterestRate = data?.effectiveInterestRate ?? 0;
  const sumOfPayments = data?.sumOfPayments ?? 0;

  const termOfContractMonths = data?.termOfContractMonths ?? 0;

  const monthlyRate = data?.monthlyRate ?? 0;
  const hideValues = isFetching;
  const error = isError ? errorMessage : undefined;

  const equityCapital = data?.equityCapital ?? 0;
  const equityCapitalRatio = data?.equityCapitalRatio ?? 0;

  const incidentalPurchaseCosts = data?.incidentalPurchaseCosts ?? 0;

  const loanAmount = data?.loanAmount ?? 0;
  const equity = formik?.equity !== undefined ? formik?.equity : 0;

  return (
    <>
      <Box width={['100%', '100%', '50%']} px={['0', '0', '2rem']} textAlign="center">
        <LoadingOverlay status={status} loadOnMount minHeight="60px">
          <Amount
            isFetching={hideValues}
            error={error}
            enabled={enabled}
            text="Finanzierung ab ca. (monatlich)"
            amount={monthlyRate}
          />
        </LoadingOverlay>
      </Box>
      <Text color="primaryDisabled" variant="small" mt={['0', '0', '2rem']} mb={['1rem', '1rem', '1rem']}>
        Dies ist eine pauschale und unverbindliche Beispielrechnung.
        <br />
        Unsere Berater helfen Ihnen gerne weiter.
      </Text>
      <Flex flexGrow="2" flexBasis="100%" flexWrap="wrap">
        <ExtendedCard
          nopadding
          variant="smallPrimary"
          position="inner"
          textIsClosed="Details zur Berechnung"
          textIsOpen="Details zur Berechnung"
        >
          <Flex flexDirection="column" mb="5">
            <Flex justifyContent="space-between">
              <Text variant="small">Kaufpreis</Text>
              <Text variant="smallBold" textAlign="right">
                {formatEuro(values.buyingPrice)}
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text variant="small">- Eigenkapital ({equityCapitalRatio} %)</Text>
              <Text variant="smallBold" textAlign="right">
                {formatEuro(equityCapital)}
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text variant="small">+ Kaufnebenkosten ({incidentalPurchaseCostsRatio} %)</Text>
              <Text variant="smallBold" textAlign="right">
                {formatEuro(incidentalPurchaseCosts)}
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text variant="small">= Nettodarlehen</Text>
              <Text variant="smallBold" textAlign="right">
                {formatEuro(loanAmount)}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" mt={3}>
              <Text variant="smallBold">Finanzierung ab ca.</Text>
              <Text variant="smallBold" textAlign="right">
                {formatEuro(monthlyRate)}
              </Text>
            </Flex>
          </Flex>
          <Text variant="smallLight">
            {t('calculationTippText')
              .replace('{jahre}', formik?.nominalInterestRateFixationInYears || fixedInterestRate)
              .replace('{gesammtsumme}', formatEuro(formik?.totalCapitalRequirements_Amount - equity || sumOfPayments))
              .replace(
                '{gebundenerSollzins}',
                formatPercent(Number(formik?.calcResult?.debitInterest || boundInterestRate) / 100)
              )
              .replace(
                '{effektiverJahreszins}',
                formatPercent(Number(formik?.calcResult?.effectiveInterest || effectiveInterestRate) / 100)
              )
              .replace('{anfangTilgung}', formatPercent(Number(formik?.repayment || initialRepayment) / 100))
              .replace('{monate}', formik?.calcResult?.termOfContractMonths || termOfContractMonths)
              .replace('{raten}', formik?.calcResult?.termOfContractMonths || termOfContractMonths)}
          </Text>
        </ExtendedCard>
      </Flex>
    </>
  );
};

InterestCosts.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default InterestCosts;
