import useTranslation from 'next-translate/useTranslation';
import NextImage from 'next/image';
import Link from 'next/link';
import * as React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import Section from '@/components/Section';
import ChecklistItem from '@/components/SideBySide/ChecklistItem';
import Text from '@/components/Text';
import { useBreakpoints } from '@/hooks';
import useAuth from '@/hooks/useAuth';

import CardRow from '../RealEstateSearch/CardRow';

const StyledFlex = styled(Flex)`
  flex-direction: row;
  @media (max-width: 821px) {
    flex-direction: column-reverse;
  }
`;
const StyledBox = styled(Box)`
  text-align: left;
  width: 50%;
  @media (max-width: 821px) {
    text-align: center;
    width: 100%;
  }
`;
const BoxButton = styled(Box)`
  margin-bottom: 4.375rem;
  @media (max-width: 821px) {
    margin-bottom: 1.688rem;
  }
`;

const ContentFlex = styled(Flex)`
  justify-content: center;
  @media (min-width: 821px), (max-width: 469px) {
    justify-content: left;
  }
`;
const Header = () => {
  const { t } = useTranslation('page-indice');
  const { authenticated } = useAuth();
  const h = t('register', {}, { returnObjects: true });
  const d = t('header', {}, { returnObjects: true });
  const checklist = t('header.checklist', {}, { returnObjects: true });
  const breakpoint = useBreakpoints();
  const isMobile = ['small', 'medium'].includes(breakpoint);
  const handleScrollSmooth = () => {
    document.getElementById('journey')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {authenticated ? null : (
        <>
          {isMobile ? (
            <Section padded>
              <Flex flexDirection="column" py={['1rem', '1rem', '1rem']}>
                <Flex>
                  <Text mb={['0', '0', '0']} variant="bodyLightSmall">
                    {h.textMobile}
                  </Text>
                </Flex>
                <Flex width="100%" textAlign="right">
                  <Link href="/auth?page=ConsultationQuery">
                    <a title={h.linkText} style={{ width: '100%' }}>
                      <Text variant="primaryLink">{h.linkText}</Text>
                    </a>
                  </Link>
                </Flex>
              </Flex>
            </Section>
          ) : (
            <Section padded>
              <Flex
                justifyContent="space-between"
                flex-direction="column"
                alignItems="center"
                py={['1rem', '1rem', '1rem']}
              >
                <Flex>
                  <Text mb={['0', '0', '0']} mr={['1.25rem', '1.25rem', '1.25rem']} variant="bodyLightSmall">
                    {h.title}
                  </Text>
                  <Text mb={['0', '0', '0']} variant="bodyLightSmall">
                    {h.text}
                  </Text>
                </Flex>
                <Flex>
                  <Link href="/auth?page=ConsultationQuery">
                    <a title={h.linkText}>
                      <Text variant="primaryLink">{h.linkText}</Text>
                    </a>
                  </Link>
                </Flex>
              </Flex>
            </Section>
          )}
        </>
      )}
      <Section bg="white">
        <StyledFlex mb={['3.75rem', '3.75rem', '3.75remn']}>
          <StyledBox px={['1rem', '1rem', '2rem']}>
            <Text as="h1" color="darkGrey" fontSize={['2.625rem', '2.625rem', '2.625rem']} variant="title">
              {d.title}
            </Text>
            <Text as="p" variant="body">
              {d.text}
            </Text>
            {checklist ? (
              <Box mb={['2.313rem', '2.313rem', '2.313rem']} mt={['2rem', '2rem', '2rem']}>
                {checklist.map((item) => (
                  <ChecklistItem key={item.id} justifyContent={['left', 'center', 'left']}>
                    {item.text}
                  </ChecklistItem>
                ))}
              </Box>
            ) : null}
            <BoxButton>
              <Button
                mb={['0', '1.25rem', '1.25rem']}
                onClick={() => {
                  handleScrollSmooth();
                }}
                width={['auto', 'auto', 'auto']}
              >
                {d.buttonText}
              </Button>
            </BoxButton>
            <ContentFlex alignItems={['left', 'center']} flexDirection={['column', 'row']}>
              <Flex mr="1.25rem">
                <Text variant="bodyLightSmall" mb={['1rem', '0', '0']}>
                  {d.infoText}
                </Text>
              </Flex>
              <Flex>
                <NextImage alt={d.logo.alternativeText} src={d.logo.url} width={d.logo.width} height={d.logo.height} />
              </Flex>
            </ContentFlex>
          </StyledBox>
          <Box
            mt={['20px', '20px', '50px']}
            px={['1rem', '0rem', '2rem']}
            width={['230px', '410px', '50%']}
            alignSelf="center"
          >
            <NextImage alt={d.image.alternativeText} src={d.image.url} width="1429" height="1631" />
          </Box>
        </StyledFlex>
      </Section>
      <Section fullWidth sx={{ zIndex: 10 }}>
        <Box textAlign="center">
          <Text as="h2" color="darkGrey" fontWeight={['700', '700', '700']} textAlign="center" variant="title">
            {t('immobilienTitle')}
          </Text>
        </Box>
        <CardRow transparent bgcolor startpage />
      </Section>
    </>
  );
};

export default Header;
