import NextImage from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import { RightArrowIcon } from '@/components/Icon/ArrowIconUiKit';
import IconWrapper from '@/components/IconWrapper/IconWrapper';
import Text from '@/components/Text';

const MyFlex = styled(Flex)`
  @media (max-width: 820px) {
    flex-direction: column;
    height: 600px;
  }
`;
const MyInnerFlexLeft = styled(Flex)`
  min-width: 568px;
  @media (max-width: 820px) {
    height: 400px;
    width: 100%;
    min-width: 100%;
  }
`;
const MyInnerFlexRight = styled(Flex)`
  @media (max-width: 820px) {
    height: 400px;
    width: 100%;
  }
`;
const Standard = ({ widgetContent }) => {
  return (
    <MyFlex flexDirection="row" height="370px" sx={{ borderRadius: '10px', overflow: 'hidden' }}>
      <MyInnerFlexLeft width="50%" bg="#3B4C5C" alignItems="center">
        <Box mx={['2.688rem', '2.688rem', '2.688rem']}>
          <Text color="#FFF" mb="1.875rem" variant="headline" mt={['0', '0', '0']}>
            {widgetContent.title}
          </Text>
          <Text color="#FFF" lineHeight="1.763rem" mb="1.875rem">
            {widgetContent.text}
          </Text>
          <Link href={widgetContent.link}>
            <a
              title={widgetContent.buttonText}
              rel={widgetContent?.newTab === false ? '' : 'noopener noreferrer'}
              target={widgetContent?.newTab === false ? '_self' : '_blank'}
            >
              <Button variant="secondary">
                <IconWrapper iconColor="black" Icon={RightArrowIcon}>
                  {widgetContent.buttonText}
                </IconWrapper>
              </Button>
            </a>
          </Link>
        </Box>
      </MyInnerFlexLeft>
      <MyInnerFlexRight width="50%" sx={{ position: 'relative' }}>
        <Box bg="transparent" sx={{ position: 'absolute', height: '100%', width: '100%', zIndex: '10000' }} />
        <NextImage
          src={widgetContent.image.url}
          layout="fill"
          objectFit="cover"
          objectPosition="center center"
          priority
        />
      </MyInnerFlexRight>
    </MyFlex>
  );
};

Standard.propTypes = {
  widgetContent: PropTypes.shape({
    buttonText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default Standard;
