/* eslint-disable no-nested-ternary */
import stylePropTypes from '@styled-system/prop-types';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';

import { CheckMarkIcon } from '../Icon';
import Text from '../Text';

const ChecklistItem = ({ auth, children, insurance, ...props }) => (
  <Flex {...props}>
    {auth ? (
      <Box mt="2" mr="12px" minWidth="18px">
        <CheckMarkIcon />
      </Box>
    ) : insurance ? (
      <Box mt="1" mr="12px" minWidth="12px">
        <CheckMarkIcon height="9px" />
      </Box>
    ) : (
      <Box mt="2" mr="12px" minWidth="18px">
        <CheckMarkIcon />
      </Box>
    )}

    {auth ? (
      <Text as="p" color="white" variant="checklist">
        {children}
      </Text>
    ) : insurance ? (
      <Text as="p" variant="checklistInsurance">
        {children}
      </Text>
    ) : (
      <Text as="p" variant="body">
        {children}
      </Text>
    )}
  </Flex>
);
ChecklistItem.propTypes = {
  ...stylePropTypes.color,
  ...stylePropTypes.space,
  children: PropTypes.node.isRequired,
};

export default ChecklistItem;
