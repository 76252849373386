import P from 'prop-types';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { border, color, layout } from 'styled-system';

const CardContainer = styled(Flex)`
  ${border};
  ${layout};
  ${color}
`;
CardContainer.defaultProps = {
  backgroundColor: 'white',
  borderRadius: '10px',
  flexDirection: 'column',
  px: ['15px', '15px', '15px'],
  py: ['12px', '12px', '12px'],
  width: ['100%', '100%', '100%'],
};

const CardOutlined = ({ children, ...props }) => {
  return <CardContainer {...props}>{children}</CardContainer>;
};

CardOutlined.propTypes = {
  children: P.node.isRequired,
};

/** @component */

export default CardOutlined;
