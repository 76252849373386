/* eslint-disable no-underscore-dangle */
// import { useWindowWidth } from '@react-hook/window-size';
import { AnimatePresence, motion, useElementScroll } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import ScrollFade from '@/components/ScrollFade';
import Section from '@/components/Section';
import Text from '@/components/Text';
import useScrollBox from '@/hooks/useScrollBox';

import Immobiliensuche from './Module/Immobiliensuche';
import Rechner from './Module/Rechner';
import Standard from './Module/Standard';

const DragEventBlocker = ({ isDragging }) => {
  const [dragging, setDragging] = useState(false);

  React.useEffect(() => {
    isDragging.onChange((current) => {
      setDragging(current);
    });
  }, [isDragging]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        pointerEvents: dragging ? 'auto' : 'none',
      }}
    />
  );
};

DragEventBlocker.propTypes = {
  isDragging: PropTypes.shape({
    onChange: PropTypes.string.isRequired,
  }).isRequired,
};

const ChipWrapper = styled(Flex)`
  background-color: #f7f7f7;
  border-radius: 100px;
  display: inline-block;
  width: fit-content;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`;

const ChipWrapperActive = styled(Flex)`
  background-color: #e2001a;
  border-radius: 100px;
  display: inline-block;
  width: fit-content;
  cursor: pointer;
`;
const typeToWidget = {
  standard: Standard,
  rechner: Rechner,
  immobilien: Immobiliensuche,
};

const FlexAnimate = styled(Flex)`
  justify-content: ${(props) => (props.isAnimate ? 'center' : '')};
  width: ${(props) => (props.isAnimate ? '100%' : '')};
`;

const JourneyWidget = ({ modul, item }) => {
  const widgetModul = modul.replace('journey.', '').replace(/-/g, '');
  const Widget = typeToWidget[widgetModul];
  return <Widget widgetContent={item} />;
};

// const MyBox = styled(Box)``;

JourneyWidget.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  modul: PropTypes.string.isRequired,
};

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const Journey = () => {
  const { t } = useTranslation('page-indice');
  const { t: h } = useTranslation('content-journey');
  const items = h('items', {}, { returnObjects: true });

  const [[page, direction], setPage] = useState([0, 0]);

  const scrollTarget = useRef(null);
  const { blockDragging, isDragging } = useScrollBox(scrollTarget);
  const { scrollXProgress } = useElementScroll(scrollTarget);

  const [sliderWidth, setSliderWidth] = useState(0);

  const [sliderChildrenWidth, setSliderChildrenWidth] = useState(0);

  const [isAnimate, setIsAnimate] = useState(false);

  useEffect(() => {
    const calcSliderWidth = () => {
      setSliderWidth(scrollTarget.current.offsetWidth);
    };
    calcSliderWidth();
    window.addEventListener('resize', calcSliderWidth);

    const calcSliderChildrenWidth = () => {
      setSliderChildrenWidth(
        Array.from(scrollTarget?.current?.childNodes).reduce((acc, node) => acc + node.offsetWidth, 0)
      );
    };
    calcSliderChildrenWidth();
    if (sliderChildrenWidth - sliderWidth < 0) {
      setIsAnimate('x');
    } else {
      setIsAnimate(false);
    }

    return () => {
      window.removeEventListener('resize', calcSliderWidth);
    };
  }, [sliderChildrenWidth, sliderWidth]);

  React.useLayoutEffect(() => {
    if (sliderChildrenWidth - sliderWidth < 0) {
      setIsAnimate('x');
    } else {
      setIsAnimate(false);
    }
  }, [sliderChildrenWidth, sliderWidth]);

  return (
    <Section bg="white" id="journey" padded>
      <Flex flexDirection="column" justifyContent="center" mb={['3.75rem', '3.75rem', '3.75rem']}>
        <Box mt={['2rem', '2rem', '2rem']} textAlign="center" width={['100%', '100%', '100%']}>
          <Text as="h2" color="darkGrey" fontWeight={['700', '700', '700']} textAlign="center" variant="title">
            {t('journey.title')}
          </Text>
          <Text variant="body" textAlign="center">
            {t('journey.text')}
          </Text>
        </Box>
        <Box sx={{ position: 'relative' }} id="outerBox">
          <Box
            ref={scrollTarget}
            sx={{
              bg: 'white',
              py: 3,
              width: 'auto',
              transition: 'all 1s ease',
              scrollPadding: '15px',
              scrollMargin: '200px',
              flexWrap: 'nowrap',
              overflowY: 'scroll',
              scrollbarWidth: 'none' /* Firefox */,
              '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
              '::-webkit-scrollbar': {
                display: 'none',
                height: 0,
                width: 0,
              },
            }}
          >
            <FlexAnimate
              role="list"
              width="fit-content"
              flex={1}
              px={['0', 0, 0]}
              sx={{ position: 'relative', '& > *': { flexShrink: 0 }, flexWrap: 'nowrap' }}
              isAnimate={isAnimate}
            >
              {items.map((item, i) => {
                const isActive = i === page;
                return (
                  <>
                    {!isActive ? (
                      <ChipWrapper
                        minWidth="fit-content"
                        mr={['10px', '10px', '10px']}
                        my={['30px', '30px', '30px']}
                        px={['16px', '16px', '16px']}
                        py={['10px', '10px', '10px']}
                        isDragging={blockDragging}
                        onClick={() => {
                          setPage([i, i - page]);
                        }}
                      >
                        <Flex alignItems="center" flexDirection="row" justifyContent="space-around">
                          <Text
                            fontSize={['0.813rem', '0.813rem', '0.813rem']}
                            letterSpacing={['0.031rem', '0.031rem', '0.031rem']}
                            variant="chipText"
                            whiteSpace="nowrap"
                          >
                            {item.chip}
                          </Text>
                        </Flex>
                      </ChipWrapper>
                    ) : (
                      <ChipWrapperActive
                        minWidth="fit-content"
                        isDragging={blockDragging}
                        mr={['10px', '10px', '10px']}
                        my={['30px', '30px', '30px']}
                        px={['16px', '16px', '16px']}
                        py={['10px', '10px', '10px']}
                        onClick={() => {
                          setPage([i, i - page]);
                        }}
                      >
                        <Flex alignItems="center" flexDirection="row" justifyContent="space-around">
                          <Flex alignItems="center" flexDirection="row" justifyContent="space-around">
                            <Text
                              fontSize={['0.813rem', '0.813rem', '0.813rem']}
                              letterSpacing={['0.031rem', '0.031rem', '0.031rem']}
                              variant="chipText"
                              color="#fff"
                              whiteSpace="nowrap"
                            >
                              {item.chip}
                            </Text>
                          </Flex>
                        </Flex>
                      </ChipWrapperActive>
                    )}
                  </>
                );
              })}
              <DragEventBlocker isDragging={isDragging} />
            </FlexAnimate>
          </Box>
          {!isAnimate && <ScrollFade progress={scrollXProgress} />}
        </Box>

        <AnimatePresence initial={false} custom={direction}>
          <Flex sx={{ position: 'relative' }}>
            <Box sx={{ overflow: 'hidden', width: '100%' }}>
              <motion.section
                key={page}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 30, duration: 2 },
                }}
              >
                <JourneyWidget key={items[page].id} item={items[page]} modul={items[page].__component} />
              </motion.section>
            </Box>
          </Flex>
        </AnimatePresence>
      </Flex>
    </Section>
  );
};

export default Journey;
