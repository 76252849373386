import useTranslation from 'next-translate/useTranslation';
import NextImage from 'next/image';
import Link from 'next/link';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import CardOutlined from '@/components/CardOutlined';
import { ArrowIcon } from '@/components/Icon';
import Section from '@/components/Section';
import Text from '@/components/Text';

const CardOutlinedWithHover = styled(CardOutlined)`
  div:nth-child(2) h5 {
    transition: all 0.2s ease;
    padding-right: 7px;
  }
  &:hover {
    div:nth-child(2) h5 {
      padding-right: 12px;
    }
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.05);
  }
`;

const MyFlex = styled(Flex)`
  a {
    margin-right: 0;
    margin-bottom: 1.25rem;
    @media (min-width: 832px) {
      margin-right: 0.983rem;
      margin-bottom: 0;
    }
  }
  a:last-child {
    margin-right: 0;
  }
`;

const MyArrowIcon = styled(ArrowIcon)`
  path {
    fill: #333333;
  }
`;

const CTABoxes = () => {
  const { t } = useTranslation('page-indice');
  const phase = t('phase', {}, { returnObjects: true });

  return (
    <Section bg="white" padded>
      <Flex justifyContent="center" mb={['3.75rem', '3.75rem', '3.75rem']}>
        <Box mt={['2rem', '2rem', '2rem']} width={['100%', '100%', '100%']}>
          <Text as="h2" color="darkGrey" fontWeight={['700', '700', '700']} textAlign="center" variant="title">
            {t('titlePhase')}
          </Text>
          <MyFlex
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            mt={['2rem', '2rem', '2rem']}
          >
            {phase.map((item) => (
              <Link href={item.link} key={item.title}>
                <a title={item.title} style={{ display: 'flex', flex: '1' }}>
                  <CardOutlinedWithHover
                    data-testid={item.title}
                    py="20px"
                    px="20px"
                    sx={{
                      cursor: 'pointer',
                      flex: '1',
                      border: '1px solid #ebebeb',
                      zIndex: '10',
                    }}
                  >
                    <Flex
                      mb="0.563rem"
                      justifyContent="center"
                      sx={{
                        background: 'rgba(226, 0, 26, 0.1)',
                        borderRadius: '50%',
                        height: '36px',
                        width: '36px',
                      }}
                    >
                      <NextImage
                        alt={item.icon?.alternativeText}
                        src={item.icon?.url}
                        width={item.icon?.width}
                        height={item.icon?.height}
                      />
                    </Flex>
                    <Flex alignItems="center" justifyContent="start" mb="0.563rem">
                      <Text as="h5" mb={['0', '0', '0']} variant="subhead">
                        {item.title}
                      </Text>
                      <MyArrowIcon style={{ fill: '#333333', transform: 'rotate(90deg)' }} />
                    </Flex>
                    <Text as="p" color="darkGrey" mb={['0', '0', '0']} mt={['0', '0', '0']} variant="subTaskText">
                      {item.text}
                    </Text>
                  </CardOutlinedWithHover>
                </a>
              </Link>
            ))}
          </MyFlex>
        </Box>
      </Flex>
    </Section>
  );
};

export default CTABoxes;
